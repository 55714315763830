import { AlertService } from './../../../../_services/alert.service';
import { PasswordService } from './../../../../_services/password.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  token : string;
  private sub : any;
  model : any = {};
  returnUrl: string = '/login';
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private passwordService : PasswordService,
    private alertService : AlertService
  ) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.model.token = params['token'];
   });
  }
  resetPassword(){
    this.passwordService.resetPassword(this.model).subscribe(
      data => {
        this.router.navigate([this.returnUrl]);
        this.alertService.success(data.message);
      },
      error => {
        //let errorResp = error.json();
        this.alertService.error(error.error.message);
      }
    );
  }

}
