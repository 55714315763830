import { AdminGuestGuard } from './../../../_guards/adminGuest.guard';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { LoginComponent } from './login/login.component';
import { GuestComponent } from './guest.component';
import { Router, Routes, RouterModule } from '@angular/router';


const guestModuleRoute :Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path:'login', component: LoginComponent, canActivate: [AdminGuestGuard]},
  { path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [AdminGuestGuard]},
  { path: 'reset-password/:token', component: ResetPasswordComponent, canActivate: [AdminGuestGuard]},
];

const routes: Routes = [
  { path:'',  component: GuestComponent, children: guestModuleRoute},
];

export const AdminGuestRoutes = RouterModule.forChild(routes);
