import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import * as moment from 'moment-timezone';
import { GoApiService } from './goApiService';
import { Auth } from '../_helpers/auth';
//import moment from 'moment-timezone';

@Injectable()
export class CardService {

    constructor(
        private apiService : GoApiService,
        private authHelper : Auth
    ) { }

    createCard(card: any) {
        let data : any = {
            name : card.name,
            description : card.description,
            address : card.address,
            city : card.city,
            region : card.region,
            country : card.country,
            airport : card.airport_address,
            card_type: card.card_type,
            category : card.category,
            things_to_do_url : card.things_to_do_url,
            book_now_url : card.book_now_url,
            gtm_label: card.gtm_label,
            best_time_to_visit: card.best_time_to_visit,
            how_to_get_there: card.how_to_get_there,
            lat : card.lat,
            long : card.long,
            croppedurl:card.cropped
        }

        return this.apiService.post('card/create', data).map(response => response.data);
    }

    upload(files, cardId, status){
        let formData: FormData = new FormData();
        for (var j = 0; j < files.length; j++) {
          formData.append("file[]", files[j], files[j].name);
        }
        return  this.apiService.post('card/udpate-files/'+cardId+"/"+status , formData);
    
    }

    editCard(card: any) {
        let data : any = {
            id : card.id,
            name : card.name,
            description : card.description,
            address : card.address,
            city : card.city,
            region : card.region,
            country : card.country,
            airport : card.airport_address,
            card_type: card.card_type,
            category : card.category,
            things_to_do_url : card.things_to_do_url,
            book_now_url : card.book_now_url,
            gtm_label: card.gtm_label,
            best_time_to_visit: card.best_time_to_visit,
            how_to_get_there: card.how_to_get_there,
            lat : card.lat,
            long : card.long,
            croppedurl:card.cropped
        }

        return this.apiService.post('card/edit-details/' + card.id, data).map(response => response.data);
    }

    upateSettings(card : any, targetedAudience , cardId){
        let data = {
            targeted_audience : JSON.stringify(targetedAudience),
            target : card.target,
            time_type : card.time_type,
            date_from : moment(card.date_from).format('YYYY/MM/DD HH:mm'),
            date_to : moment(card.date_to).format('YYYY/MM/DD HH:mm'),
            budget : card.budget,
            frequency : card.frequency,
            tz :  moment.tz.guess()
        }
        return this.apiService.post('card/update-settings/'+cardId, data);
    }

    editUpateSettings(card : any,targetedAudience, cardId){
        let data = {
            targeted_audience : JSON.stringify(targetedAudience),
            target : card.target,
            time_type : card.time_type,
            date_from : moment(card.date_from).format('YYYY/MM/DD HH:mm'),
            date_to : moment(card.date_to).format('YYYY/MM/DD HH:mm'),
            budget : card.budget,
            frequency : card.frequency,
            change_status : card.statusChange,
            tz :  moment.tz.guess()
        }
        return this.apiService.post('card/edit-settings/'+cardId, data);
    }

    getCardCreate(){
        return this.apiService.get('card/create')
        .map(response => response.data);
        
    }

    getPendingCards(page : number =1, filter: Array<any> = []){
        filter['type'] = 'pending';
        return this.apiService.get('card/list?page=' + page, filter).map(response => response.data);

    }

    getCards(page: number = 1, filter: Array<any> = []) {
        return this.apiService.get('card/list?page=' + page, filter).map(response => response.data);

    }

    deleteCardMedia(id: any) {
        let data = {
            id: id
        }
        return this.apiService.post('card/media/delete', data);
    }

    getCardDetails(id: number) {
        return this.apiService.get('card/details/' + id).map(response => response.data);
    }

    getStatsOverview(){
        return this.apiService.get('card/stat-overview').map(response => response.data);
    }

    getengagementViewGraphDate(){
        return this.apiService.get('card/engagement-view-graph').map(response => response.data);
    }

    getExport(filter: Array<any> = []){
        filter['advertiser_id'] = this.authHelper.selectedAdvertiserId();
        return this.apiService.download('card/export?', filter);
    }

    clearPendingCardEdits(id : number){
        let data = {
            id: id
        }
        return this.apiService.post('card/clear-history', data);
    }

    regionUpdate(value: string){
        return this.apiService.get('card/get-region?country='+value).map(response => response.data);
    }

    pauseThisCard(id : number){
        let data = {
            id: id
        }
        return this.apiService.post('card/pause', data);
    }

    activateThisCard(id : number){
        let data = {
            id: id
        }
        return this.apiService.post('card/activate', data);
    }

    getImage(id:number){
        let data = {
            id: id
        }
        return this.apiService.post('card/get-image', data);
    }

    getDashboardDetails(){
        return this.apiService.get('card/get-dash-details').map(response => response.data);
    }

}