import { Auth } from './../../../../_helpers/auth';
import { fadeInAnimation } from './../../../../_animations/fadeInAnimation';
import { AlertService } from './../../../../_services/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from './../../../../_services/authentication.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  animations : [fadeInAnimation],
  host: { '[@fadeInAnimation]': '' }
})
export class LoginComponent implements OnInit {

  model: any = {};
  loading: boolean = false;
  returnUrl: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private authHelper : Auth
  ) { }

  ngOnInit() {
    this.authenticationService.adminLogout();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'dashboard';
  }

  login() {
    this.loading = true;
    this.authenticationService.adminLogin(this.model.email, this.model.password)
      .subscribe(
      data => {
        let user = data.user;
        let advertiserCompanies = data.advertiser_companies;
        let selectedAdvertiserCompany = data.default;
        let token = data.token;
        if (user && token) {
            const userData = {
                token:token,
                name:user.name,
                email:user.email,
                user_id:user.id,
                profile:user.profile,
                advertisers:advertiserCompanies,
                currentAdvertiser:selectedAdvertiserCompany
            };
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            this.authHelper.setAdminUser(userData);
        }
        this.loading = false;
        this.router.navigate([this.returnUrl]);
      },
      error => {
        this.alertService.error(error.error.message);
        this.loading = false;
      }
      )
  }

}
