import { GoApiService } from './goApiService';
import { Injectable } from '@angular/core';

@Injectable()
export class PasswordService {

constructor(
    private apiService : GoApiService,
) { }

    forgotPassword(email : string){
        let data = {
            email: email
        };
        return this.apiService.post('password/forgot', data);
    }

    adminForgotPassword(email : string){
        let data = {
            email: email
        };
        return this.apiService.post('admin/password/forgot', data);
    }

    resetPassword(reset : any){
        let data = {
            email: reset.email,
            password: reset.password,
            password_confirmation : reset.confirm_password,
            token : reset.token
        };
        return this.apiService.post('password/reset', data);
    }
}