import { Component } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  loading:boolean = false;
  constructor(private router:Router) { 
    router.events.subscribe(event => {
      if(event instanceof NavigationStart) {
        this.loading = true;
      }
      // NavigationEnd
      // NavigationCancel
      // NavigationError
      // RoutesRecognized
    });
  router.events.subscribe(event => {
      if(event instanceof NavigationEnd) {
        this.loading = false;
      }
    });
  
  }
}
