import { JwtInterceptor } from './_services/jwt.Interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AdminGuestModule } from './_modules/admin/guest/guest.module';
import { AdminModule } from './_modules/admin/admin/admin.module';
import { AppRoutes } from './app.routing';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import {HttpClientModule} from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Auth } from './_helpers/auth';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutes,
    FormsModule,
    AdminGuestModule,
    AdminModule,
    BrowserAnimationsModule,
    HttpClientModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    Auth
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
