import { Auth } from './../_helpers/auth';
import { GoApiService } from './goApiService';
import { Injectable } from '@angular/core';
import { Http, Headers, Response, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map'
import { environment } from '../../environments/environment';
import { log } from 'util';

@Injectable()

export class AuthenticationService {
    constructor(
        private http: Http,
        private apiService: GoApiService,
        private authHelper: Auth
    ) { }


    login(email: string, password: string) {
        const data = {
            email: email,
            password: password
        };
        return this.apiService.post('auth/login', data).map(response => response.data);
    }

    adminLogin(email: string, password: string) {
        const data = {
            email: email,
            password: password
        };
        return this.apiService.post('admin/auth/login', data).map(
            response => response.data,
            error => error.error.error
        );
    }


    logout() {
        this.authHelper.logout();
    }

    adminLogout() {
        this.authHelper.adminLogout();
    }
}
