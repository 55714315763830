import { Router, ActivatedRoute, NavigationEnd, Params, PRIMARY_OUTLET } from "@angular/router";
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'admin-sidebar',
  templateUrl: './admin-sidebar.component.html',
  styleUrls: ['./admin-sidebar.component.css']
})
export class AdminSidebarComponent implements OnInit {

  sideMenu: string = '';
  menuActive: boolean = false;
  onLoadActive : string = '';
  constructor(
    private router : Router
  ) { 
    if(this.router.url === '/card/create'){
      this.onLoadActive = 'cards';
    }
  }

  ngOnInit() {
  }

  toggleMenu(element, event) {
    
       if(!event.target.parentElement.classList.contains("noaction")){
         if (element === this.sideMenu) {
           this.menuActive = !this.menuActive;
         }
         if (this.sideMenu === '') {
           this.menuActive = !this.menuActive;
         }
         this.sideMenu = element;
       }
       
     }

}
