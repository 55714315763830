import { GoApiService } from './../../../_services/goApiService';
import { Injectable } from '@angular/core';

@Injectable()
export class AdminService {
    constructor(
        private apiService: GoApiService
    ) { }

    getDashboardDetails() {
        return this.apiService.get('admin/dashboard/details').map(response => response.data);
    }

    getDashboardAgeGraph(date) {
        return this.apiService.get('admin/dashboard/age/graph', date).map(response => response.data);
    }

    getDashboardGenderGraph(date) {
        return this.apiService.get('admin/dashboard/gender/graph', date).map(response => response.data);
    }
}
