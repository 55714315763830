import { Pipe } from '@angular/core';

@Pipe({
    name: 'advertiserStatus'
  })
export class AdvertiserStatusPipe {
    transform(value: any, args?: string): any {
        let status = value;
        let badge: string = '';
        if (args == 'badge') {
          if (value == 0)
            badge = 'm-badge--warning';
          else if (value == 1)
            badge = 'm-badge--success';
          else if (value == 2)
            badge = 'm-badge--warning';
          else if (value == 3)
            badge = 'm-badge--warning';
          return badge;
        } else if (args == 'font') {
          if (value == 0)
            badge = 'm--font-warning';
          else if (value == 1)
            badge = 'm--font-success';
          else if (value == 2)
            badge = 'm--font-warning';
          else if (value == 3)
            badge = 'm--font-warning';
          return badge;
        }
        else {
          if (value == 0)
            status = 'Idle';
          else if (value == 1)
            status = 'Active';
          else if (value == 2)
            status = 'Suspended';
          else if (value == 3)
            status = 'Inactive';
        }
        return status;
      }
}
