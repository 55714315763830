import { CardService } from './../../../_services/card.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'stat-overview',
  templateUrl: './stat-overview.component.html',
  styleUrls: ['./stat-overview.component.css']
})
export class StatOverviewComponent implements OnInit {

  totalImpressions : number = 0;
  totalEngagements : number = 0;
  spent : number = 0;
  balance : number = 0;
  constructor(
    private CardService : CardService
  ) { }

  ngOnInit() {
    this.CardService.getStatsOverview()
    .subscribe(
      data => {
        this.totalImpressions = data.totalImpressions;
        this.totalEngagements = data.totalEngagement;
        this.balance = data.balance;
        this.spent = data.spent;

      },
      error => {

      }
    )
  }

}
