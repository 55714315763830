import { Router } from '@angular/router';
import { AuthenticationService } from './../../../_services/authentication.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.css']
})
export class AdminHeaderComponent implements OnInit {

  showOverlay : boolean = false;
  constructor(
    private authenticationService : AuthenticationService,
    private route: ActivatedRoute,
    private router : Router
  ) { }

  ngOnInit() {
  }

  logout(){
      this.authenticationService.adminLogout();
      this.router.navigate(['/login']);
  }

  toggleMenu(){
    let body = document.getElementsByTagName('body')[0];
    let lhs = document.getElementById('m_aside_left');
    console.log(lhs);
    if( body.classList.contains("m-brand--minimize")){
      body.classList.remove("m-brand--minimize", "m-aside-left--minimize");
      lhs.classList.remove("m-aside-left--on");
    }else{
      body.classList.add("m-brand--minimize", "m-aside-left--minimize");
      lhs.classList.add("m-aside-left--on")
    }
  }

  toggleMobileMenu(){
    let body = document.getElementsByTagName('body')[0];
    let lhs = document.getElementById('m_aside_left');
    if( body.classList.contains("m-brand--minimize")){
      body.classList.remove("m-brand--minimize", "m-aside-left--minimize");
      lhs.classList.remove("m-aside-left--on");
      this.showOverlay = false;
    }else{
      body.classList.add("m-brand--minimize", "m-aside-left--minimize");
      lhs.classList.add("m-aside-left--on")
      this.showOverlay = true;
    }
  }

  home(){
    this.router.navigate(['/dashboard']);
  }

}
