import { FileService } from './../../_components/multi-file-upload/file.service';
import { AuthenticationService } from './../../_services/authentication.service';
import { GuestGuard } from './../../_guards/guest.guard';
import { BreadcrumbComponent } from './../../_components/breadcrumb/breadcrumb.component';
import { CardStatusPipe } from './../../_helpers/card-status.pipe';
import { AgeDirective } from './../../_directives/age.directive';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { AdvertiserStatusPipe } from './../../_modules/admin/advertisers/advertiser-status.pipe';
import { GoApiService } from './../../_services/goApiService';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AlertComponent } from './../../_directives/alert.component';
import { NgModule } from '@angular/core';
import { Router, Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedComponent } from './shared.component';
import { OnlyNumber } from '../../_directives/onlyNumber.directive';
import { StatOverviewComponent } from '../../_modules/layout/stat-overview/stat-overview.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { Card } from '../../_models/card';
import { NgxPermissionsModule } from 'ngx-permissions';
// import {SelectModule} from 'ng2-select';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([]),
    MultiselectDropdownModule,
    NgxPaginationModule,
    
    
 
  ],
  declarations: [
    AlertComponent,
    BreadcrumbComponent,
    CardStatusPipe,
    OnlyNumber,
    AdvertiserStatusPipe,
    StatOverviewComponent,
    AgeDirective
  ],
  exports: [
    AlertComponent,
    BreadcrumbComponent,
    CardStatusPipe,
    OnlyNumber,
    AdvertiserStatusPipe,
    StatOverviewComponent,
    MultiselectDropdownModule,
    NgxPaginationModule,
    NgbModule,
    AgeDirective,
    // SelectModule
  ],
  providers: [
    GoApiService,
    GuestGuard,
    AuthenticationService,
    Card,
    FileService
  ]

})
export class SharedModule { }