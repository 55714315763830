import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable()
export class Auth {
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private location : Location
    ){}
    getToken(){
        let authUser = JSON.parse(localStorage.getItem('authUser'));
        if(authUser){
            return authUser.token;
        }else{
            return false;
        }
    }

    getAuthUser(){
        let authUser = JSON.parse(localStorage.getItem('authUser'));
        if(authUser){
            return authUser;
        }else{
            return false;
        }
    }

    selectedAdvertiser(){
        let advertiser = JSON.parse(localStorage.getItem('selectedAdvertiser'));
        if(advertiser){
            return advertiser;
        }else{
            return false;
        }
    }

    selectedAdvertiserId(){
        let advertisserId = localStorage.getItem('Advertiser')
        return advertisserId;
    }

    // setCurrentAdvertiser(advertiser){
    //     localStorage.setItem('Advertiser', advertiser.company_id);
    //     localStorage.setItem('selectedAdvertiser', JSON.stringify(advertiser));
    //     this.setPermission(advertiser.name);
    // }

    // setAuthUser(authUser){
    //     localStorage.setItem('authUser', JSON.stringify(authUser));
    //     this.setCurrentAdvertiser(authUser.currentAdvertiser);
    // }

    updateAuthUser(authUser){
        localStorage.setItem('authUser', JSON.stringify(authUser));
    }

    logout(){
        localStorage.removeItem('authUser');
        localStorage.removeItem('Advertiser');
        localStorage.removeItem('selectedAdvertiser');
        this.location.replaceState('/'); // clears browser history so they can't navigate with back button
        this.router.navigate(['login']);
    }

    getAdvertisersList(){
        let authUser = this.getAuthUser();
        if(authUser){
            return authUser.advertisers;
        }else{
            return false;
        }
    }

    updateLocalAdvertisers(advertisers){
        let authUser = this.getAuthUser();
        if(authUser){
            authUser.advertisers = advertisers;
            //this.setAuthUser(authUser)
        }else{
            return false;
        }
    }

    // setPermission(permission : string){
    //     this.permissionsService.flushPermissions();
    //     this.permissionsService.addPermission(permission);

    // }

    // refreshPermission(){
    //     let selectedAdvertiser = JSON.parse(localStorage.getItem('selectedAdvertiser'));
    //     if(selectedAdvertiser){
    //         this.setPermission(selectedAdvertiser.name);
    //     }
    // }

    setAdminUser(authUser){
        localStorage.setItem('authUser', JSON.stringify(authUser));
        localStorage.setItem('Advertiser', "0");
    }

    adminLogout(){
        localStorage.removeItem('authUser');
        this.location.replaceState('login'); // clears browser history so they can't navigate with back button
        this.router.navigate(['login']);
    }
}
