import { fadeInAnimation } from './../../../../_animations/fadeInAnimation';
import { AlertService } from './../../../../_services/alert.service';
import { PasswordService } from './../../../../_services/password.service';
import { Component, OnInit } from '@angular/core';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
  animations : [fadeInAnimation],
  host: { '[@fadeInAnimation]': '' }
})
export class ForgotPasswordComponent implements OnInit {

  model: any = {};
  loading: boolean = false;

  constructor(
    private paswordService : PasswordService,
    private alertService : AlertService
  ) { }

  ngOnInit() {
  }

  remindPassword(f: NgForm){
    this.loading = true;
    this.paswordService.adminForgotPassword(this.model.email).subscribe(
      data => {
        this.loading = false;
        this.alertService.success(data.message);
        f.resetForm();
      },
      error => {
        //let errorResp = error.json();
        this.loading = false;
        this.alertService.error(error.error.message);
      }
    );
  }

}
