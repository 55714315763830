import { GoApiService } from './../../_services/goApiService';
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable()
export class FileService {
    deletedFiles: Array<any> = [];
    constructor(
        private http: Http,
        private apiService: GoApiService,
    ) { }

    _baseURL: string = 'http://localhost:xxxx/api/fileupload/'

    upload(files, parameters){
        let headers = new Headers();
        let options = new RequestOptions({ headers: headers });
        options.params = parameters;
        return  this.http.post(this._baseURL + 'upload', files, options)
                .map(response => response.json())
                .catch(error => Observable.throw(error));

    }
    getImages(){
        return this.http.get(this._baseURL + "getimages")
                .map(response => response.json())
                .catch(error => Observable.throw(error));
    }

    deleteCardMedia(ids: Array<any>) {
        let data = {
            ids: ids
        }
        return this.apiService.post('card/media/delete', data);
    }

}