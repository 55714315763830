import { AlertService } from './alert.service';
import { ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import { HttpErrorResponse } from '@angular/common/http';
import { Auth } from '../_helpers/auth';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        private authHelper : Auth,
        private route: ActivatedRoute,
        private alertService: AlertService,
    ) {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      
      return next.handle(request).do((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // do stuff with response if you want
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            if(this.route.snapshot.firstChild.routeConfig.children[0].path == "admin"){  
              this.alertService.error('Permission Denied');
              setTimeout(() => {
                this.authHelper.adminLogout();
              }, 1000);            
                            
            }else{
              this.alertService.error('Permission Denied');
              setTimeout(() => {
                this.authHelper.logout();
              }, 1000);   
            }          
          }
        }
      });
    }
  }