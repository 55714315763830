import { AuthenticationService } from './../../../_services/authentication.service';
import { SharedModule } from './../../../_shared/shared/shared.module';
import { environment } from './../../../../environments/environment';
import { AgmCoreModule } from '@agm/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AlertService } from './../../../_services/alert.service';
import { PasswordService } from './../../../_services/password.service';
import { HttpModule } from '@angular/http';
import { FormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { AdminGuestRoutes } from './guest.routing';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GuestComponent } from './guest.component';
import { HomeComponent } from './home/home.component';
import { Ng4GeoautocompleteModule } from 'ng4-geoautocomplete';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';


@NgModule({
  imports: [
    CommonModule,
    AdminGuestRoutes,
    FormsModule,
    HttpModule,
    Ng4GeoautocompleteModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: environment.GOOGLE_API_KEY,
      libraries: ["places"]
    }),
    BrowserAnimationsModule,
    SharedModule
  ],
  declarations: [
    GuestComponent,
    LoginComponent,
    HomeComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent
    
],
providers: [
  PasswordService,
  AlertService,
  AuthenticationService
]

})
export class AdminGuestModule { }