import { DaterangepickerConfig } from 'ng2-daterangepicker';
import { NgbCarouselConfig, NgbTabsetConfig } from '@ng-bootstrap/ng-bootstrap';
import { AdminService } from './../admin.service';
import { Component, OnInit, ViewChild} from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';
declare var moment: any;

@Component({
  selector: 'admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css'],
  providers: [NgbCarouselConfig]
})

export class AdminDashboardComponent implements OnInit {
  dashboard: Array<any> = [];
  currentGraph: string = 'age';
  currentMap: string = 'impressions';

  @ViewChild("baseChart",{static: false})
    chart: BaseChartDirective;
  // lineChart
  public lineChartData: Array<any> = [];
  public monthLable: Array<any> = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  public lineEngagementData: number[] = [];
  public lineViewData: number[] = [];
  public lineAxisLable: Array<any> = [];

  public chartColors: any[] = [{
      backgroundColor: ['#9a46ff', '#ffb822', '#619cff', '#55cc38', '#f7a53d', '#f14747', '#7964a0']
  }];

  isDataAvailable: boolean = false;
  public ageChartLabels: string[] = [];
  public ageChartData: number[] = [];
  public ageChartType: string = 'doughnut';
  public ageChartOptions: any = {
    legend: {position: 'right'},
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
            const dataset = data.datasets[tooltipItem.datasetIndex];
            const total = dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
            return previousValue + currentValue;
          });

          const currentValue = dataset.data[tooltipItem.index];
          const precentage = Math.floor(((currentValue / total) * 100) + 0.5);
          return currentValue + ' (' + precentage + '%)';
        }
      }
    }
  };

  public genderChartLabels: string[] = [];
  public genderChartData: number[] = [];
  public genderChartType: string = 'doughnut';
  public genderChartOptions: any = {
    legend: {position: 'right'},
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
            const dataset = data.datasets[tooltipItem.datasetIndex];
            const total = dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
            return previousValue + currentValue;
          });
          const currentValue = dataset.data[tooltipItem.index];
          const precentage = Math.floor(((currentValue / total) * 100) + 0.5);
          return currentValue + ' (' + precentage + '%)';
        }
      }
    }
  };

  public osChartLabels: string[] = [];
  public osChartData: number[] = [];
  public osChartType: string = 'doughnut';
  public osChartOptions: any = {
    legend: {position: 'right'},
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const total = dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
            return previousValue + currentValue;
          });
          const currentValue = dataset.data[tooltipItem.index];
          const precentage = Math.floor(((currentValue / total) * 100) + 0.5);
          return currentValue + ' (' + precentage + '%)';
        }
      }
    }
  };

  public map_ChartData = [
    ['Country', 'Impression']
  ];
  public map_ChartData1 = [
    ['Country', 'Engagement']
  ];
  public map_ChartData2 = [
    ['Country', 'User']
  ];
  public map_ChartOptions = {
    colorAxis: {
      colors: ['#5ACEC2', '#FFB005', '#619CFF', '#9A46FF']
    }};
  public countryImpressions = [];
  public countryEngagements = [];
  public countryUsers = [];
  public totalCountryImpression: number = 0;
  public totalCountryEngagement: number = 0;
  public totalCountryUsers: number = 0;

  public dateInputAge = {
    start: '',
    end: ''
  };

  ageFilter = {
    start: '',
    end: ''
  };

  public dateInputGender = {
    start: '',
    end: ''
  };

  public lineChartOptions: any = {
    responsive: true
  };

  public lineChartColors: Array<any> = [
    { // Views
      backgroundColor: 'rgba(97, 156, 255, 0.5)',
      borderColor: 'rgba(97, 156, 255, 1)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
    { // Engagement
      backgroundColor: 'rgba(154, 70, 255, 0.4)',
      borderColor: 'rgba(154, 70, 255, 1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }

  ];
  public lineChartLegend: boolean = true;
  public lineChartType: string = 'line';

  genderFilter = {
    start: '',
    end: ''
  };

  constructor(
    private adminService: AdminService,
    config: NgbCarouselConfig,
    configPills: NgbTabsetConfig,
    private daterangepickerOptions: DaterangepickerConfig
  ) {
    config.interval = 5000;
    config.wrap = true;
    config.keyboard = false;
    configPills.type = 'pills';

    // date filter dropdown value settings
    this.daterangepickerOptions.settings = {
      locale: { format: 'YYYY-MM-DD' },
      alwaysShowCalendars: false,
      ranges: {
        'Reset': [moment(), moment()],
         'Last Month': [moment().subtract(1, 'month'), moment()],
         'Last 3 Months': [moment().subtract(3, 'month'), moment()],
         'Last 6 Months': [moment().subtract(6, 'month'), moment()],
         'Last 12 Months': [moment().subtract(12, 'month'), moment()],
      }
    };
  }

  ngOnInit() {
    this.adminService.getDashboardDetails()
      .subscribe(data => {
        this.dashboard = data;
        data.genderGraphData.forEach(element => {
          this.genderChartLabels.push(element.gender);
          this.genderChartData.push(element.count);
        });

        data.ageGroup.forEach(element => {
          this.ageChartLabels.push(element.agegrp);
          this.ageChartData.push(element.count);
        });

        data.engagementView.forEach(element => {
          this.lineEngagementData.push(element.total_engagement);
          this.lineViewData.push(element.total_view);
          this.lineAxisLable.push(this.monthLable[element.month - 1]);
        });

        this.lineChartData.push({data: this.lineViewData, label: 'Impression'});
        this.lineChartData.push({data: this.lineEngagementData, label: 'Engagement'});
        /// this.lineChartData.push({data:this.lineViewData, label: 'View'})

        const osData = data.deviceGraphData;
        Object.keys(osData).forEach((k) => {
          this.osChartLabels.push(k);
          this.osChartData.push(osData[k]);
        });

        this.countryImpressions = data.impressionsByCountry;
        this.countryEngagements = data.engagementByCountry;
        this.countryUsers = data.usersByCountry;
        data.impressionsByCountry.forEach(element => {
          if (element.country == 'Brasil') {
            this.map_ChartData.push(['Brazil', element.impressions]);
          } else if (element.country == 'Ελλάδα') {
            this.map_ChartData.push(['Greece', element.impressions]);
          } else {
            this.map_ChartData.push([element.country, element.impressions]);
          }

          this.totalCountryImpression += element.impressions;
        });

        data.engagementByCountry.forEach(element => {
          if (element.country == 'Brasil') {
            this.map_ChartData1.push(['Brazil', element.engagement]);
          } else if (element.country == 'Ελλάδα') {
            this.map_ChartData1.push(['Greece', element.engagement]);
          } else {
            this.map_ChartData1.push([element.country, element.engagement]);
          }

          this.totalCountryEngagement += element.engagement;
        });

        data.usersByCountry.forEach(element => {
          if (element.country == 'Brasil') {
            this.map_ChartData2.push(['Brazil', element.user]);
          } else if (element.country == 'Ελλάδα') {
            this.map_ChartData2.push(['Greece', element.user]);
          } else {
            this.map_ChartData2.push([element.country, element.user]);
          }

          this.totalCountryUsers += element.user;
        });

        // data.deviceGraphData.forEach(element => {
        //   // this.osChartLabels.push(element.gender)
        //   // this.osChartData.push(element.count)
        //   console.log(element)
        // });
        // this.genderChart.labels = this.genderChartLabels
        // this.genderChart.data = this.genderChartData
        this.isDataAvailable = true;
      },
      error => {

      });
  }

  // events
  public chartClicked(e: any): void {
    console.log(e);
  }

  public chartHovered(e: any): void {
    console.log(e);
  }

  public changeGraph(graph) {
    this.currentGraph = graph;
  }

  public changeMap(map) {
    this.currentMap = map;
  }

  // Method to track the date filter events
  private selectedDateAge(value: any, dateInputAge: any) {
    if (value.label == 'Reset') {
      dateInputAge.start = '';
      dateInputAge.end = '';
    } else {
      dateInputAge.start = value.start;
      dateInputAge.end = value.end;
      this.ageFilter.start = dateInputAge.start.format('llll');
      this.ageFilter.end = dateInputAge.end.format('llll');
    }
  }

  // Method to track the date filter events
  private selectedDateGender(value: any, dateInputGender: any) {
    if (value.label == 'Reset') {
      dateInputGender.start = '';
      dateInputGender.end = '';
    } else {
      dateInputGender.start = value.start;
      dateInputGender.end = value.end;
      this.genderFilter.start = dateInputGender.start.format('llll');
      this.genderFilter.end = dateInputGender.end.format('llll');
    }
  }

  updateAgeGraph() {
    this.adminService.getDashboardAgeGraph(this.ageFilter).subscribe(
      data => {
        this.ageChartLabels = [];
        this.ageChartData = [];

        // this.ageChartLabels.
        data.forEach(element => {
          this.ageChartLabels.push(element.agegrp);
          this.ageChartData.push(element.count);
        });
      },
      error => {}
    );
  }

  updateGenderGraph() {
    this.adminService.getDashboardGenderGraph(this.genderFilter).subscribe(
      data => {
        this.genderChartLabels = [];
        this.genderChartData = [];
        data.forEach(element => {
          this.genderChartLabels.push(element.gender);
          this.genderChartData.push(element.count);
        });
      },
      error => {}
    );
  }
}
