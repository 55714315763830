import { URLSearchParams } from '@angular/http';
import { Auth } from './../_helpers/auth';
import { AuthenticationService } from './authentication.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { environment } from './../../environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

@Injectable()
export class GoApiService {


    constructor(
        private http : HttpClient,
        private authHelper : Auth
    ){}
    public url(url: string) {
        return environment.API_URL + url;
    }

    public guestHeader() {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return { headers: headers };
    }

    public goHeader(params = []) {
        let authUser = this.authHelper.getAuthUser();
        let selectedAdvertiser = JSON.parse(localStorage.getItem('selectedAdvertiser'));
        if (authUser && authUser.token) {
            const headers = new HttpHeaders().set('Authorization', 'Bearer ' + authUser.token)
                .append('Advertiser', this.authHelper.selectedAdvertiserId());
            if (params) {
                var headerParams = new HttpParams();
                for (var key in params) {
                    if (params.hasOwnProperty(key))
                    headerParams = headerParams.append(key, params[key]);
                }
                return { headers: headers, params: headerParams };
            }
            return { headers: headers };
        }
    }

    get(path: string, params: Array<any> =[]): Observable<any> {
        return this.http.get(`${environment.API_URL}${path}`, this.goHeader(params))
            .map((res: Response) => res);
    }

    put(path: string, body: Object = {}): Observable<any> {
        return this.http.put(
            `${environment.API_URL}${path}`,
            body,this.goHeader()
        )
            .map((res: Response) => res);
    }

    post(path: string, body: Object = {}): Observable<any> {
        return this.http.post(
            `${environment.API_URL}${path}`,
            body,this.goHeader()
        )
            .map((res: Response) => res);
    }

    delete(path): Observable<any> {
        return this.http.delete(
            `${environment.API_URL}${path}`,
            this.goHeader()
        )
            .map((res: Response) => res);
    }

    file(path, body) {
        return this.http.post(
            `${environment.API_URL}${path}`,
            body,
            this.goHeader()
        )
            .map((res: Response) => res);
    }

    download(path: string, params: Array<any> =[]){
        let authUser = this.authHelper.getAuthUser();
        let value = new URLSearchParams();
        value.append('token', authUser.token);        
        for(let key in params){
            value.set(key, params[key]) 
        }
        window.open(this.url(path) + value.toString());
    }

    



}
