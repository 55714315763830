export class Card {
    id : number;
    name : string;
    description : string;
    address : string;
    city : string;
    region : string;
    country : string;
    card_type: string;
    custom: number;
    lat : number;
    long : number;
    airport : string;
    airport_lat : number;
    airport_long : number;
    book_now_url : string = "";
    gtm_label : string = "";
    best_time_to_visit : string = "";
    how_to_get_there : string = "";
    things_to_do_url : string = "";
    local_tip : string = "";
    audience_country : string;
    audience_region : string;
    audience_city : string;
    audience_address : string;
    audience_age_range : string;
    budget : string;
    status : string;
    category : number;
    holiday_types : Array<any>;
    popularity : number;
    cardGroupType: string;

}
