// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// API_URL: "http://api.gotravel.co/web/",
// api_url: "52.91.225.81"
// "54.211.16.253"
// local api: localhost:8087
export const environment = {
  production: false,
  // API_URL: "https://dev.webapi.vacaay.com/web/",
  API_URL: "https://webapi.vacaay.com/web/",
  GOOGLE_API_KEY: "AIzaSyDcp91ChDSNYk-YohR-BQTeNodIGPAyIcY",
};
