import { GoogleChartModule } from './../../../_components/google-chart/GoogleChart.module';
import { Daterangepicker } from 'ng2-daterangepicker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Router, Routes, RouterModule } from '@angular/router';
import { AdminAuthGuard } from './../../../_guards/adminAuth.guard';
import { AdminService } from './admin.service';
import { AdminHeaderComponent } from './../../layout/admin-header/admin-header.component';
import { AdminGuestGuard } from './../../../_guards/adminGuest.guard';
import { AlertService } from './../../../_services/alert.service';
import { AdminSidebarComponent } from './../../layout/admin-sidebar/admin-sidebar.component';
import { SharedModule } from './../../../_shared/shared/shared.module';
import { AdminRoutes } from './admin.routing';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminComponent } from './admin.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { ChartsModule } from 'ng2-charts';

@NgModule({
  imports: [
    CommonModule,
    AdminRoutes,
    SharedModule,
    ChartsModule,
    RouterModule.forRoot([]),
    Daterangepicker,
    GoogleChartModule
  ],
  declarations: [
    AdminComponent,
    AdminHeaderComponent,
    AdminSidebarComponent,
    AdminDashboardComponent,
    // GoogleChartComponent
],
providers: [
  AlertService,
  AdminGuestGuard,
  AdminService,
  AdminAuthGuard
]
})
export class AdminModule { }