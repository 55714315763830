import { Pipe, PipeTransform } from '@angular/core';
// 'draft' => 0,
// 'pending' => 1,
// 'active' => 2,
// 'paused' => 3,
// 'suspended' => 4,
// 'ended' => 5
@Pipe({
  name: 'cardStatus'
})
export class CardStatusPipe implements PipeTransform {

  transform(value: any, args?: string): any {
    let status = value;
    let badge: string = '';
    let gender: string = '';
    if (args == 'badge') {
      if (value == 0)
        badge = 'm-badge--warning';
      else if (value == 1)
        badge = 'm-badge--primary';
      else if (value == 2)
        badge = 'm-badge--success';
      else if (value == 3)
        badge = 'm-badge--warning';
      else if (value == 4)
        badge = 'm-badge--warning';
      else if (value == 5)
        badge = 'm-badge--brand';
      return badge;
    } else if (args == 'font') {
      if (value == 0)
        badge = 'm--font-warning';
      else if (value == 1)
        badge = 'm--font-primary';
      else if (value == 2)
        badge = 'm--font-success';
      else if (value == 3)
        badge = 'm--font-warning';
      else if (value == 4)
        badge = 'm--font-warning';
      else if (value == 5)
        badge = 'm--font-brand';
      return badge;
    }else if(args == 'gender'){
      if (value == 1)
        gender = 'Male';
      else if (value == 2)
        gender = 'Female';
      else if (value == 3)
        gender = 'Other';
      return gender;
    }
    else {
      if (value == 0)
        status = 'Draft';
      else if (value == 1)
        status = 'Pending';
      else if (value == 2)
        status = 'Active';
      else if (value == 3)
        status = 'Paused';
      else if (value == 4)
        status = 'Suspended';
      else if (value == 5)
        status = 'Ended';
    }
    return status;
  }

}