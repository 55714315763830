import { Routes, RouterModule, Router } from '@angular/router';
import { AdminAuthGuard } from './../../../_guards/adminAuth.guard';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { AdminComponent } from './admin.component';


const adminModuleRoute: Routes = [
  { path: 'dashboard', component: AdminDashboardComponent, data: { title: 'Dashboard', breadcrumb: 'Dashboard' } },
  { path: 'card', loadChildren: () => import('../admincards/admincards.module').then(m => m.AdmincardsModule), data: {breadcrumb: 'Cards' } },
  { path: 'advertiser', loadChildren: () => import('../advertisers/advertisers.module').then(m => m.AdvertisersModule), data: {breadcrumb: 'Advertisers' } },
  { path: 'profile', loadChildren: () => import('../profile/profile.module').then(m => m.ProfileModule), data: {breadcrumb: 'Profile' } },
  { path: 'users', loadChildren: () => import('../users/users.module').then(m => m.UsersModule), data: {breadcrumb: 'Users' } },
  { path: 'reports', loadChildren: () => import('../reports/reports.module').then(m => m.ReportsModule), data: {breadcrumb: 'Reports' } },
  { path: 'notification', loadChildren: () => import('../notification/notification.module').then(m => m.NotificationModule), data: {breadcrumb: 'Notifications' } },
];

const routes: Routes = [
  { path: '', component: AdminComponent, children: adminModuleRoute, canActivate: [AdminAuthGuard]},
];

export const AdminRoutes = RouterModule.forChild(routes);
